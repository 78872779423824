<template>
  <v-card>
    <v-card-title> Cadastrar nova conta </v-card-title>

    <div class="pa-5">
      <v-row>
        <v-col>
          <v-text-field
            label="Nome"
            v-model="form.firstName"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Sobrenome"
            v-model="form.lastName"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            label="Data de nascimento"
            v-model="form.date_of_birth"
            type="date"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-radio-group v-model="form.genre" row>
            <v-radio label="Masculino" value="1"></v-radio>
            <v-radio label="Feminino" value="2"></v-radio>
            <v-radio label="Personalizado" value="3"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="form.genre == '3'">
          <v-text-field
            label="Gênero"
            v-model="form.custom_genre"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            label="Perfil"
            :items="[
              { text: 'Aluno', value: 1 },
              { text: 'Professor', value: 2 },
            ]"
            v-model="form.GroupId"
            class="required"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            label="E-Mail"
            v-model="form.email"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            label="Confirmar E-Mail"
            v-model="form.confirmEmail"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            label="Senha"
            type="password"
            required
            v-model="form.password"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Confirmar senha"
            type="password"
            required
            v-model="form.confirmPassword"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex flex-row align-center">
          <v-checkbox v-model="accordingToTheTerms" :value="true"></v-checkbox>
          <span
            >Aceito os
            <v-btn
              @click.stop="showTerms = true"
              class="terms-button"
              text
              small
              >Termos e Política de Privacidade</v-btn
            ></span
          >
        </v-col>
      </v-row>
    </div>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close-dialog')">Cancelar</v-btn>

      <v-btn class="primary darken-1" @click="save" :loading="loading">
        <v-icon left>mdi-content-save</v-icon> Salvar
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="successDialog" max-width="400">
      <v-card>
        <v-card-title> Cadastro realizado com sucesso </v-card-title>
        <v-card-text>
          Olá, ficamos felizes em ter você por aqui! Faça seu login
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="onConfirmation()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showTerms" max-width="800">
      <v-card>
        <v-card-title> Termos e política de privacidade </v-card-title>
        <v-card-text v-if="termsData && termsData.terms">
          {{ termsData.terms }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="showTerms = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";
import errorHandler from "@/helpers/error_handler";
import { mapActions } from "vuex";

export default {
  directives: { mask },
  data: () => ({
    loading: false,
    successDialog: false,
    showTerms: false,
    accordingToTheTerms: false,
    valid: true,
    termsData: null,
    form: {},
  }),
  computed: {},
  methods: {
    ...mapActions(["user/signup"]),
    validateForm() {
      const errors = [];

      if (!this.accordingToTheTerms)
        errors.push(
          "Você deve concordar com os TERMOS E POLÍTICA DE PRIVACIDADE"
        );

      if (!this.form.firstName || this.form.firstName.length < 3)
        errors.push("O campo NOME é obrigatório");

      if (!this.form.lastName || this.form.lastName.length < 3)
        errors.push("O campo SOBRENOME é obrigatório");

      if (!this.form.password || this.form.password.length < 6)
        errors.push(
          "O campo SENHA é obrigatório e precisa ter no mínimo 6 caracteres "
        );

      if (this.form.password != this.form.confirmPassword)
        errors.push("Os campos SENHA e CONFIRMAR SENHA não conferem");

      if (!this.form.email) errors.push("O campo E-MAIL é obrigatório");

      if (this.form.email != this.form.confirmEmail)
        errors.push("Os campos E-MAIL e CONFIRMAR E-MAIL não conferem");

      if (!this.form.genre) errors.push("O campo GÊNERO é obrigatório");
      else if (this.form.genre == "3" && !this.form.custom_genre)
        errors.push("Informe o gênero PERSONALIZADO");

      if (!this.form.date_of_birth)
        errors.push("O campo DATA DE NASCIMENTO é obrigatório");

      if (!this.form.GroupId) errors.push("O campo PERFIL é obrigatório");

      return errors;
    },
    save() {
      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      this.signup();
    },
    async signup() {
      try {
        this.loading = true;

        await this["user/signup"](this.form);

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    onConfirmation() {
      // this.$router.push("/login");
      window.location.reload();
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
    async getTerms() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/terms`);

        this.termsData = { ...res.data };

        console.log(this.termsData);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getTerms();
  },
};
</script>

<style>
.required label::after {
  content: " *";
  color: red;
}

.terms-button {
  text-transform: lowercase;
  color: blue !important;
  padding: 0 3px !important;
}
</style>
